import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const PublishContent = () => {
  const {
    rawData: { edges: books }
  } = useStaticQuery(query)

  return (
    <div className="container mx-auto px-8 py-20">
      <Link className="inline-block" to="/publication-and-publishing">
        <svg
          className="cursor-pointer transform hover:scale-95 transition-transform duration-300 ease-in-out"
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
        >
          <path
            d="M25.5208 17.6216H9.72217"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6215 25.5208L9.72217 17.6215L17.6215 9.72217"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="17.5" cy="17.5" r="16.5" stroke="black" strokeWidth="2" />
        </svg>
      </Link>
      <div className="my-9">
        <h1 className="text-4xl text-vividBlue font-bold">Publishing</h1>
        <span className="text-sm">Publishing (Books published by “MATE publishing” and ZSEM in Croatia):</span>
      </div>
      <div className="mt-16">
        {books.length &&
          books.map(({ node: { id, title, title_hr, author, description, bookImg } }) => {
            return (
              <div className="flex flex-col items-center md:flex-row md:items-start mb-16" key={`${id}-${title}`}>
                <div className="w-32 flex flex-shrink-0 shadow-news-card mb-4 md:mb-0">
                  <GatsbyImage image={bookImg.childImageSharp.gatsbyImageData} alt={title} quality="100" />
                </div>
                <div className="flex flex-col ml-6 max-w-600px">
                  {title_hr ? <h2 className="text-sm font-bold text-vividBlue">{title_hr}</h2> : null}
                  <h2 className="text-sm font-bold text-vividBlue">{title}</h2>
                  <span className="text-sm mb-5">{author}</span>
                  <p className="text-xs">{description}</p>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default PublishContent

const query = graphql`
  query useBooks {
    rawData: allBooksJson {
      edges {
        node {
          id
          title
          title_hr
          author
          description
          bookImg {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`
