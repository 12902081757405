import React from 'react'
import SEO from 'components/SEO'
import PublishContent from 'components/PublicationPublishing/Publish/index'

const Publishing = () => {
  return (
    <>
      <SEO title="Publishing" />
      <PublishContent />
    </>
  )
}

export default Publishing
